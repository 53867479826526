@charset "UTF-8";
/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.table-row-data .td[data-v-0f307f8c]:nth-child(4),
.table-row-data[data-v-0f307f8c] :nth-child(8),
.table-row-data[data-v-0f307f8c] :nth-child(12),
.table-row-data[data-v-0f307f8c] :nth-child(16) {
  background-color: #d7d7d7;
  font-weight: bold;
}
.data-input[data-v-0f307f8c] {
  position: relative;
  box-sizing: border-box;
  padding: 0 2px;
  max-width: 80px;
  min-width: 80px;
}
.data-input[data-v-0f307f8c]  .text-input-wrapper {
  align-items: center;
  height: 100%;
}
.total-value[data-v-0f307f8c],
td[data-v-0f307f8c] {
  font-weight: 600;
}
.column-date[data-v-0f307f8c] {
  position: relative;
}
.warning-message[data-v-0f307f8c] {
  position: absolute;
  z-index: 1;
  background: white;
  border: solid 1px #e93f6f;
  border-radius: 5px;
  color: #e93f6f;
  font-size: 10px;
  padding: 4px;
  text-align: center;
  top: -40px;
  left: -40px;
  font-weight: bold;
  box-shadow: 0px 0px 5px 0px rgba(68, 68, 68, 0.26);
  width: 150px;
}
.warning-message[data-v-0f307f8c]:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e93f6f;
  bottom: -5px;
  right: 75px;
}
.isQWarning[data-v-0f307f8c]  .formattedValue {
  border: 2px solid #ffbf00;
}
.isQWarning[data-v-0f307f8c]  .formattedValue::before {
  content: "⚠";
  position: absolute;
  left: 0;
  color: #ffbf00;
  padding-left: 3px;
  font-size: 2rem;
  cursor: default;
}