/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.labels[data-v-c2952408] {
  display: flex;
  gap: 1rem;
  padding: 1rem 0.5rem;
  justify-content: space-around;
  background-color: #f5f5f5;
  font-weight: bold;
}
.loader[data-v-c2952408] {
  position: absolute;
  width: 90%;
  height: 90%;
  opacity: 0.5;
}
.add-btn[data-v-c2952408] {
  align-self: end;
  margin-right: 1rem;
}
.input[data-v-c2952408] {
  border: none;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 2px;
  text-align: right;
  width: 100%;
}
.input[data-v-c2952408]:focus {
  outline: none;
  border-color: #e93f6f;
}
.input-row[data-v-c2952408] {
  display: flex;
  gap: 1rem;
  padding: 1rem 0.5rem;
}
.input-row[data-v-c2952408]:nth-child(odd) {
  background-color: #f5f5f5;
}
.input-row[data-v-c2952408]:nth-child(even) {
  background-color: #f0f0f0;
}
.input-row[data-v-c2952408]:last-child {
  margin-bottom: 1rem;
}
.input-row.U[data-v-c2952408] {
  outline: 1px solid lightblue;
  background: #f3f9fb;
}
.input-row.D[data-v-c2952408] {
  outline: 1px solid pink;
  background: #fff8f9;
}
.input-row.I[data-v-c2952408] {
  outline: 1px solid orange;
  background: floralwhite;
}
.modal-header[data-v-c2952408] {
  padding: 1.5rem 1rem 1rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.modal-body[data-v-c2952408] {
  padding: 1rem;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 75vh;
  overflow-y: auto;
}
.invalid-text[data-v-c2952408] {
  color: #e93f6f;
  font-size: 1.4rem;
  margin-top: 1rem;
}
.modal-footer[data-v-c2952408] {
  font-size: 1.6rem;
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  gap: 1rem;
}
.button[data-v-c2952408] {
  position: relative;
  font-size: 1.6rem;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 10rem;
  padding: 1rem;
  opacity: 0.9;
  background-color: #ebebeb;
  color: black;
}
.button--primary[data-v-c2952408] {
  background-color: #e93f6f;
  color: white;
}
.modal[data-v-c2952408] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}
.modal-background[data-v-c2952408] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.281);
}
.modal-content[data-v-c2952408] {
  background-color: white;
  box-shadow: 1px 2px 10px 2px #afafaf;
  border-radius: 6px;
  z-index: 1;
  min-width: 50vw;
  width: 800px;
  max-width: calc(100vw - 2rem);
}